import local from '@/utils/local';
import axios from 'axios';

interface Venue {
  address: string;
  email: string;
  name: string;
  phone_number: string;
  id: number | null;
  center?: { lat?: number; lng?: number };
  open_data?: Record<string, OpenHour[]>;
}

interface OpenHour {
  venue_id: number;
  day_of_week: string;
  start_time: string;
  end_time: string;
}

export default async function (): Promise<void> {
  const bookingVenues: Venue[] = local.get('booking_venues') || [];
  const address = local.get('address');
  const email = local.get('Email');
  const venueName = local.get('venueName') || local.get('cafe_name');
  const phoneNumber = local.get('phone_number');

  if (!address || !email || !venueName || !phoneNumber) {
    console.error('Required local storage data is missing');
    return;
  }

  bookingVenues.unshift({
    address,
    email,
    name: venueName,
    phone_number: phoneNumber,
    id: null,
  });

  for (const item of bookingVenues) {
    if (local.get('google_map_key')) {
      item.center = await reqSearch(item.address);
    } else {
      item.center = {};
    }
    item.open_data = filterOpenHours(item.id);
  }

  local.set('aboutUsMap', bookingVenues);
}

// address -> lat and lng
const reqSearch = async (val: string): Promise<{ lat?: number; lng?: number }> => {
  let center: { lat?: number; lng?: number } = {};
  try {
    const res = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${val}&key=${local.get('google_map_key')}`
    );
    center.lat = res.data.results[0].geometry.location.lat;
    center.lng = res.data.results[0].geometry.location.lng;
  } catch (err) {
    center = {};
  }
  return center;
}

const filterOpenHours = (val: number | null): Record<string, OpenHour[]> => {
  const openHours: OpenHour[] = local.get('open_hours') || [];
  const groupByDayOfWeek = openHours
    .filter((item: OpenHour) => item.venue_id === val)
    .reduce((group: Record<string, OpenHour[]>, open_hour: OpenHour) => {
      const { day_of_week } = open_hour;
      group[day_of_week] = group[day_of_week] ?? [];
      group[day_of_week].push(open_hour);
      return group;
    }, {});

  for (const key in groupByDayOfWeek) {
    groupByDayOfWeek[key].sort(
      (a, b) => parseInt(a.start_time.slice(0, 2)) - parseInt(b.start_time.slice(0, 2))
    );
  }

  return groupByDayOfWeek;
}
